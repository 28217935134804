export default defineNuxtRouteMiddleware((to) => {
  const { status: authStatus, data: user } = useAuth()
  const nuxt = useNuxtApp()
  const runtimeConfig = useRuntimeConfig()

  // This should never happen, but just in case.
  // The user should always be authenticated (checked by the auth middleware)
  if (!user.value || authStatus.value !== 'authenticated') {
    // If the user is not authenticated and this is a TL deployment, redirect to the TL login page
    if (isTalentLabs()) {
      return navigateTo(runtimeConfig.public.tlLoginUrl, { external: true })
    }
    // Otherwise, redirect to the login page
    return navigateTo(
      nuxt.$localePath({
        name: 'auth-login',
        query: { next: to.fullPath }
      })
    )
  }
})
